/**
 * @file Styles 
 * @author Jan Zarsky <xzarsk03@fit.vutbr.cz>
 */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap&subset=latin-ext');
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
}
h1, h2, h3, h4, h5, h6, th {
  font-weight: 400;
}
p {
  margin-top: 0;
}

.control-panel {
  margin-bottom: 4px;
}

.timetable {
  display: grid;
}

.timeheader,
.dateheader,
.groupheader {
  color: #455a64;
  background-color: #cfd8dc;
  z-index: 999;
  font-weight: 400;
}
.timeheader {
  padding: 2px 6px;
  grid-row-start: 1;
  position: sticky;
  top: 0;
  margin: 0 2px 2px 2px;
  border-radius: 0 0 4px 4px;
}
.dateheader {
  padding: 2px 6px;
  grid-column-start: 1;
  display: flex;
  align-items: center;
  margin: 2px 0 2px 0;
  min-height: 5rem;
  text-align: right;
}
.groupheader {
  padding: 0 4px 0 0;
  grid-column-start: 2;
  display: flex;
  align-items: center;
  margin: 2px 2px 2px 0;
  border-radius: 0 4px 4px 0;
  font-size: 0.8rem;
  text-align: right;
}

.program-wrapper {
  padding: 2px;
  text-align: center;
  display: flex;
  align-items: stretch;
  align-content: stretch;
  overflow: hidden;
  position: relative;
}
.program-wrapper.dragged {
  transform: translateX(-9999px);
}

.program {
  flex: 1;
  min-width: 0;
  min-height: 0;
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 4px;
  background-color: #81d4fa;
  line-height: 1.5;
}
.program.violation {
  background-image: repeating-linear-gradient(
    135deg,
    rgba(0,0,0,0),
    rgba(0,0,0,0) 20px,
    #ef9a9a 20px,
    #ef9a9a 40px
  );
}
.program.filtered {
  background-color: #343a40;
}

.program-text {
  flex: 1;
  min-width: 0;
  min-height: 0;
  word-wrap: break-word;
}
.program-text h3 {
  margin: 0.5em 0 0 0;
  font-size: 1em;
  text-transform: uppercase;
}
.program-text p {
  font-size: 0.9em;
  margin-bottom: 0;
  color: #37474f;
}
.program.filtered .program-text h3 {
  color: #ffffff;
}
.program.filtered .program-text p {
  color: #b0bec5;
}

.program-edit,
.program-move,
.program-url {
  display: none;
  width: 2rem;
  height: 2rem;
  position: absolute;
  line-height: 1;
  padding: 6px;
  cursor: pointer;
  color: #455a64;
}
.program-edit {
  top: 0;
  right: 0;
  text-align: right;
  z-index: 2;
}
.program-move {
  top: 0;
  left: 0;
  text-align: left;
  z-index: 1;
  cursor: move;
}
.program-url {
  left: 0;
  bottom: 0;
  text-align: left;
  z-index: 1;
}
.program-url a {
  color: inherit;
}
.program-wrapper:hover .program-edit,
.program-wrapper:hover .program-url,
.program-wrapper:hover .program-move {
  display: block;
}

.droppable {
  margin: 2px;
  border-radius: 4px;
}
.droppable:hover {
  background-color: #dcedc8;
}
.droppable.selected {
  background-color: #9ccc65;
}
.droppable.drag-over {
  background-color: #29b6f6;
}

.color-sample {
  display: inline-block;
  width: 1.5em;
  margin-right: 5px;
}

.jumbotron .container {
  max-width: 40em;
}
